.sidebar .nav-link:hover {
  color: #fff;
  background: #0029fa !important;
}

.test_style {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0;
  overflow-y: auto;
  background-color: rgba(0,0,0,.4);
  z-index: 10000;
  pointer-events: none;
  transition: opacity .3s;
}

.test_h1{
  z-index: 1000000;
  /* margin: 100px; */
  padding: 20%;
  /*background: red;*/
}

.test_img {
  width: 60px;

  /* for animation*/
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6  {
  font-family: 'Nunito', sans-serif;
  color: #5f5f5f;
}
a , li , form{
  font-family: 'Nunito', sans-serif;
}

tr , th , div , button ,small{
  font-family: 'Nunito', sans-serif;
}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text{
  color:#5c6873!important;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.main{
  background-color: white !important;
}

#save_btn{
  margin-bottom: 1%;
  background-color: #0029fa;
  color: ghostwhite;
}

#back_btn{
  margin-bottom: 1%;
  background-color: #009900;
  color: ghostwhite;
}

.swal-text{
  color: black;
}

.swal-title{
  color: black;
}

.btn-outline-primary{
  border-color: #0029fa !important;
  color: #0029fa !important;
}

.btn-outline-primary:hover{
  color: white !important;
  background-color: #0029fa !important;
}

.btn-outline-primary::selection{
  background: #0029fa !important;
}

.ant-pagination-item-active{
  border-color: #0029fa !important;
}

.ant-pagination-item:hover{
  border-color: #0029fa !important;
}

.ant-steps-item-process .ant-steps-item-icon{
  background-color: #0029fa !important;
  border-color: #0029fa !important;
}

/*.ant-calendar-picker-input .ant-input{*/
  /*height: 100% !important;*/
/*}*/

.btn-outline-success{
  border-color: #009900 !important;
  color: #009900 !important;
}

.btn-outline-success:hover{
  color: white !important;
  background-color: #009900 !important;
}

.btn-outline-success::selection{
  background: #009900 !important;
}

.btn-outline-danger{
  border-color: #FF0000 !important;
  color: #FF0000 !important;
}

.btn-outline-danger:hover{
  color: white !important;
  background-color: #FF0000 !important;
}

.btn-outline-danger::selection{
  background: #FF0000 !important;
}



